interface Database {
  [country: string]: {
    flag: string;
    names: {
      [index: number]: string;
    };
  };
}

export const database: Database = {
  USA: {
    flag: '🇺🇸',
    names: [
      'Amelia',
      'Benjamin',
      'Caroline',
      'David',
      'Elijah',
      'Elizabeth',
      'Emma',
      'Gabriel',
      'Harper',
      'Henry',
      'Isabella',
      'Jackson',
      'Jacob',
      'James',
      'Joseph',
      'Liam',
      'Lucas',
      'Madison',
      'Mason',
      'Noah',
      'Oliver',
      'Olivia',
      'Owen',
      'Whitney',
      'William',
      'Wyatt',
    ],
  },
  Germany: {
    flag: '🇩🇪',
    names: [
      'Ana',
      'Andreas',
      'Anja',
      'Anna',
      'August',
      'Bastian',
      'Ben',
      'Caroline',
      'Christoph',
      'Dennis',
      'Emma',
      'Erich',
      'Ernst',
      'Evelyn',
      'Fabian',
      'Felix',
      'Frank',
      'Franz',
      'Fred',
      'Friedrich',
      'George',
      'Gerald',
      'Goethe',
      'Günter',
      'Hans',
      'Heinz',
      'Helmut',
      'Hildegrad',
      'Holger',
      'Horst',
      'Jakob',
      'Jasmina',
      'Jonas',
      'Karl',
      'Katharina',
      'Kathrin',
      'Klaus',
      'Koln',
      'Kurt',
      'Lara',
      'Lars',
      'Laura',
      'Lea',
      'Mario',
      'Markus',
      'Matias',
      'Max',
      'Michael',
      'Moritz',
      'Otto',
      'Paul',
      'Thomas',
      'Toni',
      'Vincent',
      'Walter',
      'Wilhelm',
    ],
  },
  Italy: {
    flag: '🇮🇹',
    names: [
      'Alida',
      'Andrea',
      'Anna',
      'Baldassarre',
      'Bellucci',
      'Benito',
      'Carla',
      'Carlo',
      'Christina',
      'Christopher',
      'Claudia',
      'Columbus',
      'Cosimo',
      'Donatella',
      'Donatello',
      'Eleonora',
      'Fabio',
      'Filippo',
      'Francesco',
      'Galileo',
      'Gandolfini',
      'Giacomo',
      'Gina',
      'Giorgio',
      'Giovanni',
      'Guarino',
      'Guilia',
      'James',
      'Leonardo',
      'Luigi',
      'Marco',
      'Mariangela',
      'Martina',
      'Mauro',
      'Monica',
      'Nicola',
      'Pablo',
      'Pietro',
      'Rachele',
      'Roberta',
      'Rosario',
      'Sandra',
      'Silvana',
      'Sophia',
      'Veronica',
      'Versace',
      'Vincent',
    ],
  },
  Spain: {
    flag: '🇪🇸',
    names: [
      'Agustín',
      'Alfredo',
      'Amigo',
      'Angela',
      'Antoni',
      'Antonio',
      'Carles',
      'Chendo',
      'Dani',
      'David',
      'Diego',
      'Emmannuel',
      'Enrique',
      'Fernando',
      'Francisco',
      'Gerard',
      'Gregorio',
      'Gustavo',
      'Josep',
      'José',
      'Juan',
      'Juanito',
      'Julen',
      'Julio',
      'Marco',
      'Marcos',
      'Maria',
      'Marta',
      'Martinez',
      'Matias',
      'Miguel',
      'Migueli',
      'Nacho',
      'Noemi',
      'Pablo',
      'Paula',
      'Pedro',
      'Pepe',
      'Pirri',
      'Roberto',
      'Ronaldo',
      'Samuel',
      'Santiago',
      'Sebastian',
      'Sergio',
      'Thiago',
      'Xavi',
      'Yarima',
      'Zarra',
    ],
  },
  France: {
    flag: '🇫🇷',
    names: [
      'Alain',
      'Alexia',
      'Angelique',
      'Antonin',
      'Arielle',
      'Audrey',
      'Bernard',
      'Bourvil',
      'Brigitte',
      'Capucine',
      'Catherine',
      'Charles',
      'Gabrielle',
      'Gaspard',
      'Guillaume',
      'Hector',
      'Henri',
      'Isabelle',
      'Jean',
      'Jeanne',
      'Judith',
      'Jules',
      'Julieia',
      'Lambert',
      'Leslie',
      'Lili',
      'Lolehne',
      'Martine',
      'Max',
      'Michael',
      'Romain',
      'Sacha',
      'Sebastian',
      'Sheryfa',
      'Simone',
      'Vincent',
      'Marc',
      'Corentin',
      'Louis',
      'Hugo',
      'Kevin',
      'Olivier',
      'Etiene',
      'Thibault',
      'Jaques',
      'Marcel',
      'Victor',
      'Gustave',
      'Arthur',
      'Colette',
      'Alexandre',
      'Alphonse',
    ],
  },
  Russia: {
    flag: '🇷🇺',
    names: [
      'Irina',
      'Andrei',
      'Artem',
      'Nick',
      'Vitali',
      'Evgeni',
      'Victor',
      'Michail',
      'Vladimir',
      'Sergey',
      'Mikhail',
      'Mike',
      'Andrey',
      'Anton',
      'Yaroslav',
      'Olexi',
      'Alexi',
      'Ilya',
      'Roman',
      'Sophia',
      'Marina',
      'Anastasia',
      'Nikolai',
    ],
  },
  Bangladesh: {
    flag: '🇧🇩',
    names: [
      'Abdul',
      'Adnan',
      'Anik',
      'Ashik',
      'Ayon',
      'Babu',
      'Bijoy',
      'Choton',
      'Cynthia',
      'Farhana',
      'Fariha',
      'Habib',
      'Hridi',
      'Imtiaz',
      'Jayed',
      'Jehad',
      'Kaysar',
      'Koushik',
      'Labib',
      'Mehedi',
      'Razzak',
      'Riad',
      'Rupom',
      'Sabbir',
      'Sadia',
      'Sagor',
      'Sajib',
      'Sakib',
      'Shakil',
      'Shammo',
      'Shourav',
      'Sowad',
      'Sultan',
      'Tamal',
      'Upol',
    ],
  },
  Brazil: {
    flag: '🇧🇷',
    names: [
      'Ademir',
      'Adriano',
      'Afonso',
      'Aldair',
      'Alexandre',
      'Batista',
      'Bebeto',
      'Branco',
      'Bruna',
      'Bárbara',
      'Cafu',
      'Cajó',
      'Careca',
      'Carla',
      'Carlos',
      'Cláudio',
      'César',
      'Dani',
      'Daniel',
      'David',
      'Denílson',
      'Dida',
      'Didi',
      'Diego',
      'Dirceu',
      'Djalma',
      'Domingos',
      'Dunga',
      'Edinho',
      'Edmundo',
      'Edu',
      'Elano',
      'Elias',
      'Emerson',
      'Fernanda',
      'Fernandinho',
      'Fernando',
      'Filipa',
      'Filipe',
      'Filomena',
      'Flávio',
      'Francisco',
      'Fred',
      'Frederico',
      'Fábio',
      'Félix',
      'Gal',
      'Garrincha',
      'Gilberto',
      'Gilmar',
      'Guilherme',
      'Gustavo',
      'Hernanes',
      'Hilderaldo',
      'Isabel',
      'Jaime',
      'Jairzinho',
      'Joca',
      'Joel',
      'Jomi',
      'Jorge',
      'Jorginho',
      'Josué',
      'Juan',
      'Juninho',
      'Kléberson',
      'Laura',
      'Leonardo',
      'Letícia',
      'Leônidas',
      'Lorena',
      'Lucas',
      'Luisão',
      'Luiz',
      'Lurdes',
      'Lusophone',
      'Magda',
      'Maicon',
      'Malu',
      'Manuela',
      'Marcelo',
      'Marco',
      'Marcos',
      'Maricota',
      'Miju',
      'Miranda',
      'Mário',
      'Müller',
      'Neymar',
      'Nicola',
      'Nilo',
      'Nuno',
      'Nílton',
      'Oscar',
      'Paula',
      'Paulinho',
      'Paulo',
      'Pedro',
      'Pelé',
      'Pepe',
      'Philippe',
      'Ramires',
      'Raí',
      'Renato',
      'Ricardo',
      'Rita',
      'Rivaldo',
      'Rivellino',
      'Roberto',
      'Robinho',
      'Rodolfo',
      'Rodrigo',
      'Ronaldinho',
      'Ronaldo',
      'Roque',
      'Rosa',
      'Susana',
      'Teresa',
      'Thiago',
      'Toninho',
      'Tostão',
      'Waldir',
      'Willian',
      'Zeca',
      'Zizinho',
    ],
  },
  India: {
    flag: '🇮🇳',
    names: [
      'Abhijit',
      'Katrina',
      'Sonal',
      'Anushka',
      'Kareena',
      'Abhinav',
      'Amit',
      'Kangana',
      'Vijay',
      'Dilawar',
      'Amir',
      'Probir',
      'Karisma',
      'Anusheel',
      'Ghosh',
      'Gupta',
      'Ishita',
      'mahesh',
      'Pranav',
      'Preeti',
      'Radhika',
      'Rahul',
      'Rajat',
      'Rajesh',
      'Ramesh',
      'Rohit',
      'sharma',
      'Shiva',
      'Shreeram',
      'Subhas',
    ],
  },
  Turkey: {
    flag: '🇹🇷',
    names: [
      'Abdullah',
      'Ahmet',
      'Ajda',
      'Akbaba',
      'Akbay',
      'Aleyna',
      'Ali',
      'Alişan',
      'Alpaslan',
      'Alpay',
      'Arda',
      'Arif',
      'Arzu',
      'Aslı',
      'Asya',
      'Atilla',
      'Atiye',
      'Ayla',
      'Aylin',
      'Aynur',
      'Baba',
      'Basri',
      'Bendeniz',
      'Bengü',
      'Beyza',
      'Burak',
      'Burcu',
      'Bülent',
      'Can',
      'Caner',
      'Cem',
      'Cemil',
      'Cenk',
      'Colin',
      'Demet',
      'Demir',
      'Deniz',
      'Derya',
      'Doğa',
      'Ebrar',
      'Ebru',
      'Ece',
      'Edis',
      'Ela',
      'Elif',
      'Emina',
      'Emir',
      'Emre',
      'Engin',
      'Ercan',
      'Erdal',
      'Erdoğan',
      'Ergün',
      'Erol',
      'Ersan',
      'Ertuğrul',
      'Fatih',
      'Fatma',
      'Ferhat',
      'Feyyaz',
      'Füsun',
      'Gökdeniz',
      'Gökhan',
      'Gülben',
      'Güliz',
      'Gülseren',
      'Gülçin',
      'Gülşen',
      'Hakan',
      'Halil',
      'Hami',
      'Hamit',
      'Hande',
      'Hasan',
      'Hatice',
      'Hilal',
      'Hiranur',
      'Ibrahim',
      'Kaya',
      'Mehmet',
      'Mevlüt',
      'Muhterem',
      'Murat',
      'Mustafa',
      'Naci',
      'Nazan',
      'Neslihan',
      'Nez',
      'Nil',
      'Nilüfer',
      'Nur',
      'Nükhet',
      'Ogan',
      'Ogün',
      'Okan',
      'Omer',
      'Recep',
      'Reyhan',
      'Rober',
      'Rüştü',
      'Rıza',
      'Sabri',
      'Seda',
      'Sedat',
      'Semiha',
      'Serdar',
      'Sergen',
      'Serhat',
      'Serkan',
      'Sertab',
      'Servet',
      'Sezen',
      'Sibel',
      'Soner',
      'Suat',
      'Sıla',
      'Talat',
      'Tanju',
      'Tuncay',
      'Turgay',
      'Tuğba',
      'Tülay',
      'Tümer',
      'Volkan',
      'Yalın',
      'Yeşim',
      'Yilmaz',
      'Yusuf',
      'Zehra',
      'Zeynep',
      'Zuhal',
    ],
  },
};
